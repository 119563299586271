var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('viewcard--c',{attrs:{"title":_vm.$route.params.id > 0 ? 'Atualizar Campanha' : 'Cadastrar Campanha',"btsave":_vm.$route.params.id > 0 ? _vm.btedit : _vm.btcreate,"btdelete":null,"btback":{},"busy":_vm.isloading},on:{"clicked-save":_vm.validationForm}},[_c('validation-observer',{ref:"campaignRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nome *"}},[_c('validation-provider',{attrs:{"name":"Nome da Campanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nome"},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Status"}},[(_vm.status.length > 0)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.status,"autocomplete":"off","clearable":false},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}}):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Parâmetros de Campanha *"}},[_c('validation-provider',{attrs:{"name":"Parâmetros de Campanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.campaignParameters.length > 0)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.campaignParameters,"autocomplete":"off","clearable":false},model:{value:(_vm.campaignParametersSelected),callback:function ($$v) {_vm.campaignParametersSelected=$$v},expression:"campaignParametersSelected"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Horário de Funcionamento *"}},[_c('validation-provider',{attrs:{"name":"Horário de Funcionamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hours.length > 0)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.hours,"autocomplete":"off","clearable":false},model:{value:(_vm.hoursSelected),callback:function ($$v) {_vm.hoursSelected=$$v},expression:"hoursSelected"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lista de Contato *"}},[_c('validation-provider',{attrs:{"name":"Lista de Contato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.contact,"input-props":_vm.inputPropsC,"section-configs":_vm.contactConfigs},on:{"input":_vm.getContact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"size":"lg"}},[_vm._v(" "+_vm._s(_vm.getinitials(suggestion.item))+" ")]),_vm._v(" "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.queryContact),callback:function ($$v) {_vm.queryContact=$$v},expression:"queryContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }